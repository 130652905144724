<template>
    <div class="container-fluid bg-dark px-5 d-none d-lg-block">
        <div class="row gx-0 align-items-center" style="height: 45px;">
            <div class="col-lg-8 text-center text-lg-start mb-lg-0">
                <div class="d-flex flex-wrap">
                    <a href="#" class="text-light me-4">DPUPR KABUPATEN BLORA</a>
                    <a href="#" class="text-light me-4"><i class="fas fa-phone-alt text-danger me-2"></i>(0296) 531004</a>
                </div>
            </div>
            <div class="col-lg-4 text-center text-lg-end">
                <div class="d-flex align-items-center justify-content-end">
                    <a href="#" class="btn nav-fill me-6 text-white">#SimtaruBlora</a>
                </div>
            </div>
        </div>
    </div>
    <!-- Topbar End -->
    <div class="container-fluid position-relative p-0">
        <nav class="navbar navbar-expand-lg navbar-light bg-white px-4 px-lg-5 py-3 py-lg-0">
            <a href="/" class="navbar-brand p-0">
                <h5 class="text-danger m-0 pmisdanger"><img src="../../../../../src/assets/img/berandaNgumahNew.png" height="60"></h5>
                <!-- <img src="img/logo.png" alt="Logo"> -->
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span class="fa fa-bars"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <div class="navbar-nav ms-auto py-0">
                    <a href="/"
                        :class="[((route.name == 'Beranda') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        BERANDA
                    </a>
                    <a :href="'/GIS/'+bytes"
                        :class="[((route.name == 'GIS') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        PETA
                    </a>
                    <div class="nav-item dropdown">
                        <a href="#" data-bs-toggle="dropdown"
                            :class="[(((route.name == 'Aduan') || (route.name == 'ITR') || (route.name == 'CetakITR')) ? 'nav-link active dropdown-toggle' : 'nav-link dropdown-toggle')]">
                            LAYANAN TATA RUANG
                        </a>
                        <div class="dropdown-menu m-0">
                            <a href="/ITR"
                                :class="[((route.name == 'ITR') ? 'dropdown-item active' : 'dropdown-item')]">
                                INFORMASI TATA RUANG
                            </a>
                            <a href="/ITR"
                                :class="[((route.name == 'ITR') ? 'dropdown-item active' : 'dropdown-item')]">
                                DOKUMEN KAJIAN
                            </a>
                            <a href="/ITR"
                                :class="[((route.name == 'ITR') ? 'dropdown-item active' : 'dropdown-item')]">
                                REKOMENDASI LSD
                            </a>
                            <a href="/CetakITR"
                                :class="[((route.name == 'CetakITR') ? 'dropdown-item active' : 'dropdown-item')]">
                                CARI PERMOHONAN
                            </a>
                            <a href="/Aduan"
                                :class="[((route.name == 'Aduan') ? 'dropdown-item active' : 'dropdown-item')]">
                                ADUAN TATA RUANG
                            </a>
                        </div>
                    </div>
                    <div class="nav-item dropdown">
                        <a href="#" data-bs-toggle="dropdown"
                            :class="[(((route.name == 'DaftarKetentuanUmum') || (route.name == 'DaftarKetentuanKhusus')) ? 'nav-link active dropdown-toggle' : 'nav-link dropdown-toggle')]">
                            KETENTUAN TATA RUANG
                        </a>
                        <div class="dropdown-menu m-0">
                            <a href="/DaftarKetentuanUmum"
                                :class="[((route.name == 'DaftarKetentuanUmum') ? 'dropdown-item active' : 'dropdown-item')]">
                                KETENTUAN UMUM TATA RUANG
                            </a>
                            <a href="/DaftarKetentuanKhusus"
                                :class="[((route.name == 'DaftarKetentuanKhusus') ? 'dropdown-item active' : 'dropdown-item')]">
                                KETENTUAN KHUSUS TATA RUANG
                            </a>
                        </div>
                    </div>
                    <a href="/DaftarRegulasi"
                        :class="[((route.name == 'DaftarRegulasi') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        PUBLIKASI
                    </a>
                    <a href="/KontakKami"
                        :class="[((route.name == 'KontakKami') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        KONTAK KAMI
                    </a>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';

export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        swal
    },
    data() {
        return {
            tabaktif: encodeURIComponent(CryptoJS.AES.encrypt('', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString()),
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            bytes : encodeURIComponent(CryptoJS.AES.encrypt('Kosong', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString()),
        };
    },
    methods: {
        gantibahasa(bahasanya) {
            localStorage.setItem("bahasasistem", bahasanya);
            location.reload();
        },
    },
    mounted() {

    },
}
</script>

<style></style>